body {
    /*background-image: url(${process.env.PUBLIC_URL + '/patron-small.png'}  );*/
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
    background: black;
}
.imgVictord2exp{
    max-width: 50em;
    margin: 5em;
}